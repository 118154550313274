import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { EnvironmentContext } from "../contexts/EnvironmentContext.js";
import { getConfig } from "../config.js";
import { formatNodeName, formatDate, mapStatus } from "./formatUtils.js";
import { useNavigate } from "react-router-dom";

export const useApiUtils = () => {
  const { env } = useContext(EnvironmentContext);
  const [config, setConfig] = useState(getConfig(env));
  const navigate = useNavigate();

  useEffect(() => {
    setConfig(getConfig(env));
  }, [env]);

  const fetchWorkflowInstances = async ({
    token,
    domain,
    column,
    direction,
    activeFilters,
    showPreviousVersions,
    isSuperdupont,
    selectedCustomer,
    page,
  }) => {
    const apiUrl = config.apiOrigin;
    const params = {
      domain: domain,
      sort_by: column,
      sort_order: direction,
      status: activeFilters.status
        ? activeFilters.status.toLowerCase()
        : undefined,
      search: activeFilters.name || undefined,
      start_date: activeFilters.lastRun
        ? activeFilters.lastRun.start
        : undefined,
      end_date: activeFilters.lastRun ? activeFilters.lastRun.end : undefined,
      latest_versions_only: !showPreviousVersions,
      page: page,
      limit: 20,
      is_superdupont: isSuperdupont,
      customer: selectedCustomer || undefined,
    };

    console.log("params", params);

    const response = await axios.get(`${apiUrl}/filter/workflows/grouped`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      params: params,
    });

    return response.data;
  };

  const fetchCustomers = async (token, domain) => {
    try {
      const apiUrl = config.apiOrigin;
      const response = await axios.get(`${apiUrl}/customers`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        params: {
          domain: domain,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  };

  const searchWorkflows = async (searchString, workflows, token) => {
    const apiUrl = config.apiOrigin;

    try {
      await axios.post(
        `${apiUrl}/workflows/search`,
        {
          search_string: searchString,
          workflows: workflows,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
    } catch (error) {
      console.error("Error searching workflows:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      } else if (error.request) {
        console.error("Request data:", error.request);
      } else {
        console.error("Error message:", error.message);
      }
    }
  };

  const fetchFilteredInstances = async (
    instanceId,
    theseNewPage,
    theseNewFilters,
    theseNewSortColumn,
    theseNewSortDirection,
    token,
    domain,
    identifierName,
    searchTerm,
    versions
  ) => {
    try {
      const params = {
        status: theseNewFilters.status,
        run_start: theseNewFilters.run_start,
        run_end: theseNewFilters.run_end,
        sort_by: theseNewSortColumn,
        sort_order: theseNewSortDirection,
        page: theseNewPage,
        limit: 25,
        workflow_instance_id: instanceId,
        domain: domain,
        identifier_name: identifierName,
        search_term: searchTerm,
        versions: versions,
      };

      console.log("params", params);

      const apiUrl = config.apiOrigin;
      const response = await axios.get(`${apiUrl}/workflows/instances/filter`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        params: params,
      });

      console.log("RAW data", response.data);

      if (response.status !== 200) {
        throw new Error("Failed to fetch filtered instances");
      }

      const data = response.data.filtered_instances || [];

      const processedInstances = data.map((instance) => {
        return {
          id: instance.id,
          startTime: formatDate(instance.start_time),
          endTime: formatDate(instance.end_time),
          status: mapStatus(instance.status),
          outputDetails: `Actions: ${instance.action_count}, LLM Calls: ${instance.llm_call_count}`,
          outputs: instance.outputs,
          error: instance.error || "N/A",
          identifierId: instance.identifier_id || "N/A",
          identifierName: instance.identifier_name || "N/A",
          identifierValue: instance.identifier_value || "N/A",
          workflowVersion: instance.workflow_version || "N/A",
        };
      });

      return { response, processedInstances };
    } catch (error) {
      console.error("Error fetching filtered instances:", error);
      if (error.response && error.response.status === 403) {
        navigate("/not-authorized");
      }
      return [];
    }
  };

  const fetchWorkflowStructure = async (
    workflow_instance_id,
    token,
    domain
  ) => {
    try {
      const apiUrl = config.apiOrigin;
      const response = await axios.get(
        `${apiUrl}/workflows/${workflow_instance_id}/structure`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          params: {
            workflow_instance_id: workflow_instance_id,
            domain: domain,
          },
        }
      );

      if (response.status !== 200) {
        throw new Error("Network response was not ok");
      }

      return response.data.map((node) => ({
        ...node,
        function_name: formatNodeName(node.function_name),
      }));
    } catch (error) {
      console.warn("Error fetching workflow structure:", error);
      return null;
    }
  };

  const fetchChildWorkflows = async (
    workflowInstanceIdList,
    currentCustomerId,
    token,
    domain,
    thisWorkflowInstanceId
  ) => {
    try {
      const apiUrl = config.apiOrigin;
      const response = await axios.post(
        `${apiUrl}/workflows/children`,
        {
          workflow_instance_id_list: workflowInstanceIdList,
          customer_id: currentCustomerId,
          domain: domain,
          this_workflow_instance_id: thisWorkflowInstanceId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      const processedChildWorkflows = {};
      Object.entries(response.data).forEach(([parentId, children]) => {
        processedChildWorkflows[parentId] = children.map((child) => {
          const startTime = child.created_at
            ? new Date(child.created_at)
            : null;
          const endTime = child.end_time ? new Date(child.end_time) : null;

          return {
            id: child.id,
            name: child.name,
            display_name: child.display_name,
            workflow_id: child.workflow_id,
            startTime: startTime ? formatDate(startTime) : "N/A",
            endTime: endTime ? formatDate(endTime) : "N/A",
            status: mapStatus(child.status),
            outputDetails: `Actions: ${child.action_count}, LLM Calls: ${child.llm_call_count}`,
            error: child.error || "N/A",
            parent_workflow_instance_id: child.parent_workflow_instance_id,
          };
        });
      });

      return processedChildWorkflows;
    } catch (error) {
      console.error("Error fetching child workflows:", error);
      return {};
    }
  };

  const fetchObservableSummary = async (
    observableId,
    onChunkReceived,
    token,
    abortController
  ) => {
    const apiUrl = config.apiOrigin;
    const response = await fetch(
      `${apiUrl}/observable_summary/${observableId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        signal: abortController.signal,
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch summary");
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder("utf-8");
    let done = false;

    while (!done) {
      const { value, done: doneReading } = await reader.read();
      done = doneReading;
      const chunk = decoder.decode(value, { stream: true });
      onChunkReceived(chunk);
    }
  };

  const fetchLlmCallSummary = async (
    nodeId,
    onChunkReceived,
    token,
    abortController
  ) => {
    const apiUrl = config.apiOrigin;
    const response = await fetch(`${apiUrl}/llm_call_summary/${nodeId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      signal: abortController.signal,
    });

    if (!response.ok) {
      throw new Error("Failed to fetch summary");
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder("utf-8");
    let done = false;
    let buffer = "";

    while (!done) {
      const { value, done: doneReading } = await reader.read();
      done = doneReading;

      if (value) {
        buffer += decoder.decode(value, { stream: true });

        const startIndex = buffer.indexOf("__IMAGE_DATA_START__");
        const endIndex = buffer.indexOf("__IMAGE_DATA_END__");

        if (startIndex !== -1 && endIndex !== -1) {
          const imageDataStr = buffer.substring(
            startIndex + "__IMAGE_DATA_START__".length,
            endIndex
          );

          try {
            const images = JSON.parse(imageDataStr);
            onChunkReceived({ type: "images", data: images });

            const beforeImages = buffer.substring(0, startIndex);
            const afterImages = buffer.substring(
              endIndex + "__IMAGE_DATA_END__".length
            );

            if (beforeImages) {
              onChunkReceived(beforeImages);
            }

            buffer = afterImages;
          } catch (error) {
            console.error("Error parsing image data:", error);
          }
        } else if (!buffer.includes("__IMAGE_DATA_START__")) {
          onChunkReceived(buffer);
          buffer = "";
        }
      }
    }

    if (buffer) {
      onChunkReceived(buffer);
    }
  };

  const fetchLatestWorkflowInstance = async (
    workflowInstanceId,
    token,
    domain
  ) => {
    const apiUrl = config.apiOrigin;
    const response = await axios.get(`${apiUrl}/workflow/latest_instance`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      params: {
        workflow_instance_id: workflowInstanceId,
        domain: domain,
      },
    });

    if (!response.data) {
      throw new Error("No workflow instance found for the given workflow ID");
    }

    return response.data;
  };

  const fetchTotalRuns = async (domain, isSuperdupont, token) => {
    try {
      const apiUrl = config.apiOrigin;
      const response = await axios.get(`${apiUrl}/total_runs`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        params: {
          domain: domain,
          is_superdupont: isSuperdupont,
        },
      });

      if (response.data && typeof response.data.total_runs === "number") {
        return response.data.total_runs;
      } else {
        console.error("Unexpected response format:", response.data);
        return null;
      }
    } catch (error) {
      console.error("Error fetching total runs:", error);
      return null;
    }
  };

  const endSession = async (token, sessionId) => {
    console.log("sessionId HERE", sessionId);
    if (sessionId) {
      try {
        const apiUrl = config.apiOrigin;
        const response = await axios.post(
          `${apiUrl}/sessions/${sessionId}/end`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        console.log("Session ended:", response.data);
        sessionStorage.removeItem("session_id");
        sessionStorage.clear();
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.warn("Session not found:", sessionId);
        } else {
          console.error("Error ending session:", error);
        }
      }
    }
  };

  const fetchWorkflowInstanceInfo = async (
    workflowInstanceId,
    token,
    domain
  ) => {
    try {
      const apiUrl = config.apiOrigin;
      const response = await axios.get(`${apiUrl}/workflow_instance_info`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        params: {
          workflow_instance_id: workflowInstanceId,
          domain: domain,
        },
      });

      if (response.status !== 200) {
        throw new Error("Failed to fetch workflow instance info");
      }

      const workflowResult = response.data;

      if (!workflowResult) {
        throw new Error("Workflow instance not found");
      }

      const workflowInfo = {
        workflow_id: workflowResult.workflow_id,
        display_name: workflowResult.display_name,
        description: workflowResult.description,
        status: workflowResult.status,
        created_at: workflowResult.created_at
          ? formatDate(workflowResult.created_at)
          : "N/A",
        end_time: workflowResult.end_time
          ? formatDate(workflowResult.end_time)
          : "N/A",
      };

      return workflowInfo;
    } catch (error) {
      console.error("Error fetching workflow instance info:", error);
      if (error.response && error.response.status === 403) {
        navigate("/not-authorized");
      }
      return null;
    }
  };

  const fetchWorkflowsForLast7Days = async (
    token,
    domain,
    onChunkReceived,
    abortController
  ) => {
    try {
      const apiUrl = config.apiOrigin;
      const response = await fetch(`${apiUrl}/last_report?domain=${domain}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        signal: abortController.signal,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch workflows for the last 7 days");
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
      let done = false;

      while (!done) {
        const { value, done: doneReading } = await reader.read();
        done = doneReading;
        const chunk = decoder.decode(value, { stream: true });
        onChunkReceived(chunk);
      }
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Fetch request was aborted.");
      } else {
        console.log("Error fetching workflows for the last 7 days:", error);
      }
    }
  };

  const fetchIdentifiers = async (
    token,
    domain,
    workflowId,
    workflowInstanceId
  ) => {
    try {
      const apiUrl = config.apiOrigin;
      const response = await axios.get(`${apiUrl}/get_identifiers`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        params: {
          domain: domain,
          workflow_id: workflowId,
          workflow_instance_id: workflowInstanceId,
        },
      });
      const identifiers = response.data.identifiers;
      const formattedIdentifiers = identifiers.map((identifier) => {
        const id = identifier.id || null;
        const identifier_name = identifier.identifier_name || null;
        const workflowId = identifier.workflow_id || null;

        if (!id && !identifier_name && !workflowId) {
          console.warn("Identifier with missing fields:", identifier);
        }

        return { id, identifier_name, workflowId };
      });
      return formattedIdentifiers;
    } catch (error) {
      console.error("Error fetching identifiers:", error);
      throw error;
    }
  };

  const fetchWorkflowVersions = async (token, domain, workflowInstanceId) => {
    try {
      const apiUrl = config.apiOrigin;
      const response = await axios.get(`${apiUrl}/versions`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        params: {
          domain,
          workflow_instance_id: workflowInstanceId,
        },
      });
      return response.data.versions;
    } catch (error) {
      console.error(
        "Full error response:",
        JSON.stringify(error.response?.data?.detail)
      );
      console.error("Error type:", typeof error.response?.data?.detail);
      console.error(
        "Full response data:",
        JSON.stringify(error.response?.data)
      );
      console.error("Status:", error.response?.status);
      console.error("Headers:", error.response?.headers);
      return [];
    }
  };

  return {
    fetchWorkflowInstances,
    fetchCustomers,
    searchWorkflows,
    fetchFilteredInstances,
    fetchWorkflowStructure,
    fetchChildWorkflows,
    fetchObservableSummary,
    fetchLlmCallSummary,
    fetchLatestWorkflowInstance,
    fetchTotalRuns,
    endSession,
    fetchWorkflowInstanceInfo,
    fetchWorkflowsForLast7Days,
    fetchIdentifiers,
    fetchWorkflowVersions,
  };
};
