import React, { useCallback, useRef, useState, useEffect } from "react";
import WorkflowRow from "./WorkflowRow.js";
import WorkflowFilterRow from "./WorkflowFilterRow.js";
import { useApiUtils } from "../utils/apiUtils.js";
import { useAuth0 } from "@auth0/auth0-react";
import { getDomainFromEmail } from "../utils/formatUtils.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

function WorkflowTable({
  combinedRuns,
  handleSortClick,
  getSortIcon,
  runStartDisplay,
  runEndDisplay,
  toggleDropdown,
  openStartDropdown,
  openEndDropdown,
  openStatusDropdown,
  handleFilterToggle,
  activeFilters,
  setRunStartFilter,
  setRunStartDisplay,
  setRunEndFilter,
  setRunEndDisplay,
  total,
  isLoading,
  isDrawerOpen,
  drawerRef,
  showCustomCalendar,
  dateRange,
  setShowCustomCalendar,
  setCustomDateType,
  statusDropdownRef,
  startDropdownRef,
  endDropdownRef,
  selectedRunId,
  childWorkflows,
  handleWorkflowLinkClick,
  handleWorkflowClick,
  handleRunClick,
  toggleExpand,
  getStatusIcon,
  expandedRunIds,
  handlePageChange,
  currentPage,
  totalPages,
  loading,
  handleSeeMoreClickError,
  setFixedBottomHeight,
  searchDropdownRef,
  openSearchDropdown,
  handleSearch,
  selectedIdentifier,
  selectedIdentifierValue,
  identifiers,
  clearSearch,
  customDateType,
  workflowInstanceId,
  handleVersionSelect,
  openVersionDropdown,
  selectedVersions,
  setSelectedVersions,
}) {
  const [hasUserInteracted, setHasUserInteracted] = useState(false);
  const observer = useRef();
  const tableRef = useRef();
  const [spinningColumn, setSpinningColumn] = useState(null);
  const [versions, setVersions] = useState([]);
  const { fetchWorkflowVersions } = useApiUtils();
  const { user, getAccessTokenSilently } = useAuth0();
  const [tempSelectedVersions, setTempSelectedVersions] = useState([]);

  useEffect(() => {
    const loadVersions = async () => {
      try {
        const token = await getAccessTokenSilently();
        const domain = getDomainFromEmail(user.email);
        const fetchedVersions = await fetchWorkflowVersions(
          token,
          domain,
          workflowInstanceId
        );
        setVersions(fetchedVersions);
        setSelectedVersions(fetchedVersions);
        handleVersionSelect(fetchedVersions);
      } catch (error) {
        console.error("Error loading versions:", error);
      }
    };

    if (workflowInstanceId) {
      loadVersions();
    }
  }, [workflowInstanceId]);

  const handleMouseDown = (e) => {
    if (!hasUserInteracted) {
      setHasUserInteracted(true);
    }
    e.preventDefault();
    const startY = e.clientY;
    const startHeight = tableRef.current.offsetHeight;
    const minHeight = window.innerHeight * 0.15;
    const maxHeight = window.innerHeight * 0.5;

    const onMouseMove = (e) => {
      const newHeight = Math.min(
        Math.max(startHeight - (e.clientY - startY), minHeight),
        maxHeight
      );
      tableRef.current.style.height = `${newHeight}px`;
      setFixedBottomHeight(newHeight);
    };

    const onMouseUp = () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };

  const lastElementRef = useCallback(
    (node) => {
      if (loading || currentPage >= totalPages) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && currentPage < totalPages) {
            observer.current.disconnect();
            handlePageChange(currentPage + 1);
          }
        },
        {
          root: null,
          rootMargin: "100px",
          threshold: 0.1,
        }
      );

      if (node) observer.current.observe(node);
    },
    [loading, currentPage, totalPages, handlePageChange]
  );

  return (
    <div
      className={`fixed-bottom ${isDrawerOpen ? "open" : ""}`}
      ref={drawerRef}
    >
      <WorkflowFilterRow
        activeFilters={activeFilters}
        handleFilterToggle={handleFilterToggle}
        toggleDropdown={toggleDropdown}
        openStatusDropdown={openStatusDropdown}
        openStartDropdown={openStartDropdown}
        openEndDropdown={openEndDropdown}
        runStartDisplay={runStartDisplay}
        runEndDisplay={runEndDisplay}
        setRunStartFilter={setRunStartFilter}
        setRunStartDisplay={setRunStartDisplay}
        setRunEndFilter={setRunEndFilter}
        setRunEndDisplay={setRunEndDisplay}
        showCustomCalendar={showCustomCalendar}
        dateRange={dateRange}
        setShowCustomCalendar={setShowCustomCalendar}
        setCustomDateType={setCustomDateType}
        statusDropdownRef={statusDropdownRef}
        startDropdownRef={startDropdownRef}
        endDropdownRef={endDropdownRef}
        searchDropdownRef={searchDropdownRef}
        openSearchDropdown={openSearchDropdown}
        handleSearch={handleSearch}
        selectedIdentifier={selectedIdentifier}
        selectedIdentifierValue={selectedIdentifierValue}
        identifiers={identifiers}
        clearSearch={clearSearch}
        customDateType={customDateType}
        total={total}
        isLoading={isLoading}
        handleMouseDown={handleMouseDown}
        versions={versions}
      />
      <div className="table-container" ref={tableRef}>
        <div className="table-body-container">
          <table className="workflow-table">
            <thead>
              <tr>
                <th></th>
                <th
                  className="sortable"
                  onClick={() => {
                    const column = "startTime";
                    setSpinningColumn(column);
                    handleSortClick("startTime", "startTime");
                    setTimeout(() => setSpinningColumn(null), 200);
                  }}
                >
                  Run Start {getSortIcon("startTime", spinningColumn)}
                </th>
                <th
                  className="sortable"
                  onClick={() => {
                    const column = "endTime";
                    setSpinningColumn(column);
                    handleSortClick("endTime", "endTime");
                    setTimeout(() => setSpinningColumn(null), 200);
                  }}
                >
                  Run End {getSortIcon("endTime", spinningColumn)}
                </th>
                <th
                  className="sortable"
                  onClick={() => {
                    const column = "status";
                    setSpinningColumn(column);
                    handleSortClick("status", "status");
                    setTimeout(() => setSpinningColumn(null), 200);
                  }}
                >
                  Status {getSortIcon("status", spinningColumn)}
                </th>
                <th>Errors</th>
                <th className="table-header-cell">
                  <div className="App-filter-dropdown">
                    <div className="header-content">
                      <span>Version</span>
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className="small-chevron-versions"
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleDropdown("version");
                          setTempSelectedVersions(selectedVersions);
                        }}
                      />
                      {openVersionDropdown && (
                        <div className="App-filter-options-workflows-versions">
                          <div className="dropdown-option-versions">
                            <input
                              type="checkbox"
                              checked={
                                tempSelectedVersions.length === versions.length
                              }
                              onChange={() => {
                                const newVersions =
                                  tempSelectedVersions.length ===
                                  versions.length
                                    ? []
                                    : [...versions];
                                setTempSelectedVersions(newVersions);
                              }}
                            />
                            <span>All</span>
                          </div>
                          {versions.map((version) => (
                            <div
                              key={version}
                              className="dropdown-option-versions"
                              onClick={() => {
                                const newVersions =
                                  tempSelectedVersions.includes(version)
                                    ? tempSelectedVersions.filter(
                                        (v) => v !== version
                                      )
                                    : [...tempSelectedVersions, version];
                                setTempSelectedVersions(newVersions);
                              }}
                            >
                              <input
                                type="checkbox"
                                checked={tempSelectedVersions.includes(version)}
                                onChange={(e) => {
                                  e.stopPropagation(); // Prevent double-triggering when clicking checkbox directly
                                  const newVersions =
                                    tempSelectedVersions.includes(version)
                                      ? tempSelectedVersions.filter(
                                          (v) => v !== version
                                        )
                                      : [...tempSelectedVersions, version];
                                  setTempSelectedVersions(newVersions);
                                }}
                              />
                              <span>{version}</span>
                            </div>
                          ))}
                          <button
                            className="App-submit-versions"
                            onClick={() => {
                              setSelectedVersions(tempSelectedVersions);
                              handleVersionSelect(tempSelectedVersions);
                              toggleDropdown("version");
                            }}
                          >
                            Done
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </th>
                <th style={{ borderRadius: "0px 4px 0px 0px" }}></th>
              </tr>
            </thead>
            <tbody>
              {combinedRuns.map((workflow, index) => (
                <WorkflowRow
                  key={workflow.id}
                  workflow={workflow}
                  isLast={index === combinedRuns.length - 5}
                  selectedRunId={selectedRunId}
                  childWorkflows={childWorkflows}
                  handleWorkflowClick={handleWorkflowClick}
                  handleRunClick={handleRunClick}
                  toggleExpand={toggleExpand}
                  getStatusIcon={getStatusIcon}
                  expandedRunIds={expandedRunIds}
                  lastElementRef={
                    index === combinedRuns.length - 5 &&
                    currentPage < totalPages
                      ? lastElementRef
                      : null
                  }
                  handleSeeMoreClickError={handleSeeMoreClickError}
                  handleWorkflowLinkClick={handleWorkflowLinkClick}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default WorkflowTable;
