import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faGripLines } from "@fortawesome/free-solid-svg-icons";
import Calendar from "react-calendar";
import NodeKey from "./NodeKey.js";
import { formatIdentifier } from "../utils/formatUtils.js";

const statusDisplayMap = {
  failed: "Failed",
  completed: "Success",
  created: "No Status",
  warning: "Warning",
};

function WorkflowFilterRow({
  activeFilters,
  handleFilterToggle,
  toggleDropdown,
  openStatusDropdown,
  openStartDropdown,
  openEndDropdown,
  runStartDisplay,
  runEndDisplay,
  setRunStartFilter,
  setRunStartDisplay,
  setRunEndFilter,
  setRunEndDisplay,
  showCustomCalendar,
  dateRange,
  setShowCustomCalendar,
  setCustomDateType,
  statusDropdownRef,
  startDropdownRef,
  endDropdownRef,
  searchDropdownRef,
  openSearchDropdown,
  handleSearch,
  selectedIdentifier,
  selectedIdentifierValue,
  identifiers,
  clearSearch,
  customDateType,
  total,
  isLoading,
  handleMouseDown,
}) {
  const [searchTerm, setSearchTerm] = React.useState("");
  const [isSearchActive, setIsSearchActive] = React.useState(false);

  const handleSearchWrapper = (identifier, term) => {
    setIsSearchActive(!!term);
    handleSearch(identifier, term);
  };

  return (
    <>
      {showCustomCalendar && (
        <div className="custom-calendar-container">
          <Calendar
            selectRange={true}
            onChange={(dates) => {
              handleFilterToggle(customDateType, dates);
              setShowCustomCalendar(false);
            }}
            value={dateRange}
            className="custom-calendar"
          />
        </div>
      )}
      <div className="App-filter-row-workflows">
        <div className="App-filter-dropdown" ref={statusDropdownRef}>
          <button
            className={`App-filter-button ${
              activeFilters.status ? "active" : ""
            }`}
            onClick={() => toggleDropdown("status")}
          >
            {activeFilters.status
              ? statusDisplayMap[activeFilters.status]
              : "Status"}{" "}
            <FontAwesomeIcon icon={faChevronDown} className="small-chevron" />
          </button>
          {activeFilters.status && (
            <button
              className="App-filter-clear"
              onClick={() => {
                handleFilterToggle("status", null);
              }}
            >
              ×
            </button>
          )}
          {openStatusDropdown && (
            <div className="App-filter-options-workflows">
              <div
                className="dropdown-option"
                onClick={() => handleFilterToggle("status", "failed")}
              >
                Failed
              </div>
              <div
                className="dropdown-option"
                onClick={() => handleFilterToggle("status", "warning")}
              >
                Warning
              </div>
              <div
                className="dropdown-option"
                onClick={() => handleFilterToggle("status", "completed")}
              >
                Success
              </div>
              <div
                className="dropdown-option"
                onClick={() => handleFilterToggle("status", "created")}
              >
                No Status
              </div>
            </div>
          )}
        </div>
        <div
          className="App-filter-dropdown"
          ref={startDropdownRef}
          onClick={() => toggleDropdown("runStart")}
        >
          <button
            className={`App-filter-button ${runStartDisplay ? "active" : ""}`}
          >
            {runStartDisplay || "Run Start"}{" "}
            <FontAwesomeIcon icon={faChevronDown} className="small-chevron" />
          </button>
          {runStartDisplay && (
            <button
              className="App-filter-clear"
              onClick={(e) => {
                e.stopPropagation();
                setRunStartFilter([null, null]);
                setRunStartDisplay(null);
                handleFilterToggle("runStart", null);
              }}
            >
              ×
            </button>
          )}
          {openStartDropdown && (
            <div className="App-filter-options-workflows">
              <div
                className="dropdown-option"
                onClick={() => handleFilterToggle("runStart", "Last 7 days")}
              >
                Last 7 days
              </div>
              <div
                className="dropdown-option"
                onClick={() => handleFilterToggle("runStart", "Last 30 days")}
              >
                Last 30 days
              </div>
              <div
                className="dropdown-option"
                onClick={() => {
                  toggleDropdown(null);
                  setCustomDateType("runStart");
                  setShowCustomCalendar(true);
                }}
              >
                Custom date range
              </div>
            </div>
          )}
        </div>
        <div
          className="App-filter-dropdown"
          ref={endDropdownRef}
          onClick={() => toggleDropdown("runEnd")}
        >
          <button
            className={`App-filter-button ${runEndDisplay ? "active" : ""}`}
          >
            {runEndDisplay || "Run End"}{" "}
            <FontAwesomeIcon icon={faChevronDown} className="small-chevron" />
          </button>
          {runEndDisplay && (
            <button
              className="App-filter-clear"
              onClick={(e) => {
                e.stopPropagation();
                setRunEndFilter([null, null]);
                setRunEndDisplay(null);
                handleFilterToggle("runEnd", null);
              }}
            >
              ×
            </button>
          )}
          {openEndDropdown && (
            <div className="App-filter-options-workflows">
              <div
                className="dropdown-option"
                onClick={() => handleFilterToggle("runEnd", "Last 7 days")}
              >
                Last 7 days
              </div>
              <div
                className="dropdown-option"
                onClick={() => handleFilterToggle("runEnd", "Last 30 days")}
              >
                Last 30 days
              </div>
              <div
                className="dropdown-option"
                onClick={() => {
                  toggleDropdown(null);
                  setCustomDateType("runEnd");
                  setShowCustomCalendar(true);
                }}
              >
                Custom date range
              </div>
            </div>
          )}
        </div>
        {identifiers.length > 0 && (
          <>
            <div
              className="App-filter-dropdown"
              ref={searchDropdownRef}
              onClick={() => {
                toggleDropdown("searchBy");
              }}
            >
              <button
                className={`App-filter-button-search ${
                  selectedIdentifier ? "active" : ""
                }`}
              >
                {selectedIdentifier
                  ? formatIdentifier(selectedIdentifier.identifier_name)
                  : "Search by"}{" "}
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="small-chevron"
                />
              </button>
              {openSearchDropdown && (
                <div className="App-filter-options-workflows">
                  {identifiers.map((identifier) => {
                    return (
                      <div
                        key={identifier.id}
                        className="dropdown-option"
                        onClick={() => handleSearchWrapper(identifier, null)}
                      >
                        {formatIdentifier(identifier.identifier_name)}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div className="App-search-input-container">
              <input
                type="text"
                placeholder={
                  selectedIdentifierValue
                    ? formatIdentifier(selectedIdentifierValue.identifier_name)
                    : ""
                }
                value={searchTerm}
                onChange={(e) => {
                  const newValue = e.target.value;
                  setSearchTerm(newValue);
                  if (selectedIdentifier) {
                    if (newValue === "") {
                      setIsSearchActive(false);
                      clearSearch();
                    } else {
                      handleSearchWrapper(selectedIdentifier, newValue);
                    }
                  }
                }}
                className="styled-search-input-workflow"
                disabled={!selectedIdentifier}
              />
              {searchTerm && (
                <button
                  className="App-filter-clear-search"
                  onClick={() => {
                    setSearchTerm("");
                    setIsSearchActive(false);
                    clearSearch();
                  }}
                >
                  ×
                </button>
              )}
            </div>
          </>
        )}
        <div className="number-of-runs">
          <strong>{total}</strong> {total === 1 ? "run" : "runs"}
        </div>{" "}
        {isLoading && <div className="loading-spinner-workflow"></div>}
        <FontAwesomeIcon
          icon={faGripLines}
          className="resizer-icon"
          onMouseDown={handleMouseDown}
        />
        <div className="node-key-container">
          <NodeKey />
        </div>
      </div>
    </>
  );
}

export default WorkflowFilterRow;
