export function getConfig(env = "dev") {
  const isProd = env === "prod";

  // Ensure all non-localhost URLs use HTTPS
  const ensureHttps = (url) => {
    if (!url) return url;
    if (url.includes("localhost")) return url;
    return url.replace("http://", "https://");
  };

  const config = {
    domain: isProd
      ? process.env.REACT_APP_AUTH0_DOMAIN_PROD
      : process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: isProd
      ? process.env.REACT_APP_AUTH0_CLIENT_ID_PROD
      : process.env.REACT_APP_AUTH0_CLIENT_ID,
    apiOrigin: ensureHttps(
      isProd
        ? process.env.REACT_APP_API_ORIGIN_PROD
        : process.env.REACT_APP_API_ORIGIN
    ),
    audience: isProd
      ? process.env.REACT_APP_AUTH0_AUDIENCE_PROD
      : process.env.REACT_APP_AUTH0_AUDIENCE,
    version: "2025.01.009",
  };

  return config;
}

const config = getConfig();

export default config;
